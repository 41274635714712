import { Component, EventEmitter, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgClass } from '@angular/common';
import { SubmitButtonComponent } from '@resparke/components/submit-button/submit-button.component';
import { ResidentService } from 'src/app/shared/services/resident/resident.service';
import { ReplacePipe } from '@resparke/pipes/replace/replace.pipe';

export interface DialogData {
  heading: string;
  body?: string;
  requireCode?: boolean;
  'facility-admin-unlock-body'?: string;
  'super-admin-unlock-body'?: string;
  residentFullName?: string;
}


@Component({
  selector: 'app-unlock-resident-dialog',
  imports: [MatDialogModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule,
    MatButtonModule, MatIconModule, SubmitButtonComponent, NgClass, ReplacePipe],
  templateUrl: './unlock-resident-dialog.component.html',
  styleUrls: ['./unlock-resident-dialog.component.scss']
})
export class UnlockResidentDialogComponent {

  code = new FormControl('', Validators.required);
  isLoading = false;
  showCode = false;
  codeVerified = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<UnlockResidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private residentService: ResidentService
  ) {
    // Facility Admin or locked device use both use same dialog scenario, but use different content
    this.data.body = this.data['facility-admin-unlock-body']  || this.data.body;
  }

  verifyCode() {
    this.code.markAllAsTouched();

    if (!this.code.valid) {
      return;
    }
    this.isLoading = true;

    this.dialogRef.disableClose = true;
    this.residentService.verifyUnlockCode(this.code.value)
      .subscribe(res => {
        if (res) {
          this.codeVerified.emit(true);
        } else {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
          this.code.setErrors({ codeInvalid: true });
        }
      });
  }

  unlock() {
    this.isLoading = true;
    this.codeVerified.emit(true);
  }

  getErrorMessages(): string {

    if (this.code.invalid) {
      const error = Object.keys(this.code.errors!)[0];
      switch (error) {
        case 'required':
          return 'This field is required';
        case 'codeInvalid':
          return 'The unlock code is incorrect. Please try again or contact support'
        default:
          return '';
      }
    } else {
      return '';
    }
  }
}
